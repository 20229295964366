@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');

$pikayellow: #FDD100;
$pixeltext: 'VT323', serif;
$textfont: 'Rubik', sans-serif;

@keyframes movingBackground {
  from {background-position: 0 0;}
  to {background-position: 75px 75px;}
}

@keyframes movingDots {
  from {background-position: 0 0;}
  to {background-position: 5px 5px;}
}

@keyframes flashingText {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes logoRotate {
  from {
    transform: rotate(-1.5deg);
  }
  to {
    transform: rotate(1.5deg);
  }
}

@keyframes bounce {
  0%, 40%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-3px);
  }
}

html {
  background-color: white;

  * {
    box-sizing: border-box !important;
  }
}

h1, h2, h3 {
  font-family: $textfont;
  font-weight: 600;
  color: black;
  text-transform: uppercase;
}

h4 {
  font-family: $textfont;
  font-weight: 500;
}

.pixel {
  font-family: $pixeltext;
}

p {
  font-family: 'VT323';
}

.home-button {
  position: relative;
  font-family: $textfont;
  color: black;
  background-color: transparent;
  text-transform: none;
  font-size: inherit;
  width: auto; 
  height: auto;
  padding: 0;

  img {
    height: 14px;
    margin-right: 6px;
    margin-bottom: 2px;
  }
}

button {
  position: relative;
  font-family: $pixeltext;
  color: $pikayellow;
  background-color: black;
  text-transform: uppercase;
  font-size: 1.5rem;
  width: 175px; 
  height: 56px;
  padding: 10px;
  text-align: center;

  span {
    display: inline-block;
    font-family: $pixeltext;

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation-delay: 0.1s * $i !important;
      }
    }
  }
}

.no-touch-device button:hover {
  span {
    animation: bounce 1s infinite;
  }
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.banner-button {
  width: auto;
  height: auto;
  padding: 0;
  position: relative;
  font-family: $pixeltext;
  text-transform: uppercase;
  color: $pikayellow;
  border: none;
  font-size: 2rem;
  background-color: black;

  h4 {
    cursor: pointer;
    transition: all .5s linear;
    opacity: 1;
    width: 100%;
    color: $pikayellow;
    margin-right: 20px;
    animation: flashingText 1.5s alternate infinite;
  }

  &:hover h4 {
    opacity: 1;
    animation: none;
  }

  span {
    font-family: $pixeltext;
    display: inline-block;

    @for $i from 1 through 30 {
      &:nth-child(#{$i}) {
        animation-delay: 0.1s * $i !important;
      }
    }
  }
  
  &:hover span {
    animation: bounce 1s forwards;
  }
}

.fadeInImage {
  opacity: 0; /* Initially set to transparent */
  transition: opacity 0.5s ease; /* Define the transition */
}

.fadeInImage.visible {
  opacity: 1; /* When the src attribute is added, it fades in */
}

.dotted {
  transition: all .2s cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: rgb(236, 236, 236);
  transform: translate(0,0);
  cursor: pointer;
  border: 3px solid black;
  text-align: center;
}

.no-touch-device .dotted:hover, 
.dotted.selected {
  background-color: $pikayellow;
  background-image: url('../public/images/dots.png');
  background-size: 5px;
  filter: drop-shadow(3px 3px 0px black);
  transform: translate(-3px, -3px);
  animation: movingDots .5s linear infinite;
}

.App {
  background-color: white;
  height: auto;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  position: relative; 
}

.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.game-container {
  margin-left: auto;
  margin-right: auto;
  height: 100%; /* Occupy full height of the .app container */
  overflow-y: auto; 
  position: relative;
}

.game-container::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.game-container::-webkit-scrollbar-track {
  background-color: transparent; /* Track color */
}

.game-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 1);
  transform: translateX(-10px);
}

.moving-background {
  height: 100%;
  width: 100%;
  background-image: url('../public/images/pokeball-texture.png');
  background-size: 75px;
  animation: movingBackground 5s linear infinite;
  opacity: 0.05;
}

#start-screen-container {
  color: black;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.start-screen {
  color: black;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;

  #title {
    top: -40px;

    #logo-top {
      filter: drop-shadow(3px 3px 0px $pikayellow);
      max-width: 31.25%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }

    #logo-middle {
      filter: drop-shadow(6px 6px 0px $pikayellow);
    }

    #logo-bottom {
      filter: drop-shadow(3px 3px 0px $pikayellow);
      max-width: 600px;   
      max-width: 46.87%;   
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
    }
  }

  .press-to-start {
    width: 100%;
    height: 50px;
    background-color: black;
    opacity: 0.9;
    position: absolute;
    bottom: 50px;
    z-index: 1;
  }
}

#how-to-play-screen {
  color: black;
  width: 100%;
  position: relative;
  min-height: 540px;

  #how-to-play-title {
    filter: drop-shadow(4px 4px 0px $pikayellow);
    max-width: 700px;
    margin-bottom: 70px;
    max-width: 460px;
  }

  .instructions-container {
    height: auto;

    p {
      font-family: $textfont;
      text-transform: none;
      font-weight: normal;
      max-width: 800px;
      text-align: center;
      font-size: 1.2rem;
      color: black;
      padding: 20px 20px;
    }

    .card {
      width: 31.5%;
      background-color: rgb(236, 236, 236);

      img {
        transition: all .2s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }

    .card-frame {
      height: 200px;
      overflow: hidden;
      border-bottom: 3px solid black;
      background-color: black;
    }

    .card:nth-child(1) {

      &:hover img {
        transform: translate(2px, -11px) scale(1.3);
      }

      img {
        transform: translate(5.38%, 19.12%) scale(2);
      }
    }

    .card:nth-child(2) {
      .absolute {
        object-fit: cover;
      }
      img {
        transition: all .2s cubic-bezier(0.075, 0.82, 0.165, 1);
        filter: drop-shadow(4px 4px 4px black);
      }

      &:hover img {
        animation: bounce 2s ease alternate infinite;
      }
      #lightning {
        max-width: 31.7%;
        left: 7%;
        top: 20%;
      }

      #basic{
        max-width: 45%;
        right: 7%;
        top: 25%;
        animation-delay: 1s;
      }

    }

    .card:nth-child(3) {

      &:hover img {
        transform: translate(2px, -11px) scale(1.3);
      }

      img {
        transform: translate(134.41%, 76.48%) scale(6);
      }
    }
  }
}

#categories-screen {
  min-height: 540px;

  #categories-title {
    filter: drop-shadow(4px 4px 0px $pikayellow);
    max-width: 980px;
    margin-bottom: 70px;
  }

  #tiles {
    width: 69%;
  }

  #category-images {
    width: 29%;
    overflow: visible;

    img {
      height: 270px;
      width: 372px;
      position: absolute; 
      top: -10px;
      left: 0%;
      border: 0;
      max-width: none;
    } 
  }

  .tile {
    width: 32%;
    padding: 25px 10px;
    margin-bottom: 10px;
  }

  .warning-text-container {
    width: 69%;
    text-align: center;
    bottom: 50px;
  }
}

#game-length-screen {
  min-height: 540px;

  #game-length-title {
    filter: drop-shadow(4px 4px 0px $pikayellow);
    max-width: 900px;
    margin-bottom: 50px;
  }

  p {
    font-size: .9rem;
    font-family: $textfont;
  }

  .card {
    width: 23%;
    margin-bottom: 10px;

    h4 {
      font-size: 1.2rem;
    }

    .overlay-image {
      background-size: cover;
      background-position: center;
      height: 100%;
      width: 100%;
      mix-blend-mode: darken;
      opacity: 0;
      transition: all .05s linear;
    }

    &.selected {
      .overlay-image {
        opacity: 1;
      }
      .image-container .character {
        transform: scale(1);
        filter: drop-shadow(3px 3px 0px black);
      }
    }

    .description {
      border-top: 3px solid black;
    }

    .image-container {
      height: 250px;
      overflow: hidden;

      .character {
        transition: all .2s cubic-bezier(0.075, 0.82, 0.165, 1);
        transform: scale(.9);
        filter: drop-shadow(0px 0px 0px black);
      }

      &#green {
        background-color: #24A82B;

        .character {
          top: -80px;
        }
      }

      &#blue {
        background-color: #15498D;

        .character {
          top: -60px;
        }
      }

      &#red {
        background-color: #C43921;

        .character {
          top: -30px;
        } 
      }

      &#boss {
        background-color: #726c6f;

        .character {
          top: -35px;
        }
      }
    }

  }
  
  .start-game {
    width: 100%;
    margin-top: 20px;
  }

  .sets {
    margin-top: 40px;
  }

  .warning-text-container {
    width: 100%;
    bottom: 0px;
  }
}

.no-touch-device #game-length-screen {
  .card:hover {
    .overlay-image {
      opacity: 1;
    }
    .image-container .character {
      transform: scale(1);
      filter: drop-shadow(3px 3px 0px black);
    }
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}

.hide-dim {
  opacity: 0;
}

.show-dim {
  opacity: 1;
}

.hide-content {
  transform: translateX(200%) !important;
  opacity: 0 !important;
}

.show-content {
  transform: translateX(0);
  opacity: 1;
}

.show-answer {
  transform: scale(1) !important;
  opacity: 0;
}

.hide-answer {
  transform: scale(0.3);
  opacity: 1;
}

.dim-game {
  width: 100%;
  height: calc(100vh - 108px);
  height: calc(calc(var(--vh, 1vh) * 100) - 108px);
  top: 54px;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease-in;
}


#game-screen-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  overflow: hidden;
}

#game-screen {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  #content {
    margin-top: 54px;

    .mid-col {
      transition: transform 0.4s ease, opacity 0.3s ease;
      top: -10vh;
    }
    
    .answer-reveal {
      height: calc(100vh - 108px);
      height: calc(calc(var(--vh, 1vh) * 100) - 108px);
      transition: all 0.3s ease;
      overflow: hidden !important;
      padding-left: 20px;
      padding-right: 20px;

      .correct-text-reveal, .incorrect-text-reveal {
        height: 7%;
        margin: 10px 0;

        img {
          filter: drop-shadow(2px 2px $pikayellow);
        }

      }
      .correct-img-reveal{
        height: 85%;
        
        img {
          filter: drop-shadow(2px 2px 5px rgba(0,0,0,.5));
          object-fit: contain;
          height: 100%;
          align-self: flex-start;
          transform-origin: center;
        }
      }
    }
  }

  #navbar {
    padding: 10px 20px;
    border-bottom: 3px solid black;
    background-color: white;
    height: 54px;

    .middle {
      h4 {
        font-size: 1.3rem;
        background-color: $pikayellow;
        padding: 0px 150px;
        border-radius: 20px;
        background-color: $pikayellow;
        background-image: url('../public/images/dots.png');
        background-size: 5px;
        animation: movingDots .5s linear infinite;
      }
    }
  }

  #hint-bar {
    padding: 10px 20px;
    bottom: 0;
    left: 0;
    height: 54px;
    border-top: 3px solid black;
    background-color: white;

    .right h4 {
      background-color: black;
      border-radius: 20px;
      padding: 5px 20px; 
      color: $pikayellow;
      z-index: 2;
    }
  }

  .title {
    text-align: center;
    margin: 30px 0;

    img {
      height: 20px;
      filter: drop-shadow(2px 2px 0px $pikayellow);

      &:last-child {
        margin-top: 5px;
        height: 50px;
        animation: logoRotate 2s linear alternate infinite;
      }
    }
  }

  .card-image {
    top: 177px; // 130px top min, 26px bottom min
    left: -130px; // 160px left min, -130 right min
    transform: scale(2); // 2 min, 10 max
    width: 600px;
    height: 825px;

    // for scale 10:
    // top: 2275px top min, -93px bottom min;
    // left: 2015px left min, -2004 right min;
  }

  .frame-container {
    width: 639px;
    height: 402px;
    border: 8px solid white;
    overflow: hidden;
    filter: drop-shadow(4px 4px 10px black);
    background-color: white;
  }

  .frame {
    z-index: 1;
    width: 639px;
    height: 402px;

    h3 {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 1.5rem;
      height: 36px;
      width: 105px;
      position: absolute;
    }
  }

  .submit-container {
    background-color: white;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    filter: drop-shadow(4px 4px 10px black);
    background-color: $pikayellow;
    background-image: url('../public/images/dots.png');
    background-size: 5px;
    animation: movingDots .5s linear infinite;
    text-align: center;

    input {
      font-size: 1.7rem;
      background-color: transparent;
      font-family: $textfont;
      padding-bottom: 3px;
      text-align: center;
      margin: 10px;
      transform: translateX(-10px);
      &:focus {
        border-radius: 0;
        outline: none;
      }
    }
    button {
      right: 0;
      width: 100px;
      height: 100%;
      margin-left: 10px;

      h4 {
        line-height: normal;
      }
    }
  }

  .correct-answer-reveal {
    z-index: 2;
  }

  .input-answer {
    border: 2px solid black;
    position: relative;
    z-index: 3;
  }

  h3 {
    font-family: $textfont;
    color: black;
  }

  .hint {
    text-align: center;
    cursor: pointer;
    font-family: $pixeltext;
    width: 70px;
    border: 2px solid black;
    padding: 3px;

    &:hover {
      filter: drop-shadow(2px 2px 0px black);
      transform: translate(-2px, -2px);  
    }
  }

  .lifeline-selected {
    opacity: 30%;
    cursor: default;
    pointer-events: none;
  }

  .lifeline-unselected {
    opacity: 1;
    cursor: pointer;
  }

  .hide-hint {
    display: none;
  }

  .show-hint {
    display: block;
  }
}

#loading-page {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  font-size: 50px;
  text-align: center;

  img {
    object-fit: contain;
    filter: drop-shadow(10px 10px 5px black);
    width: 200px;
    height: 200px;
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .absolute {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50%; /* or any specific size */
    height: 50%; /* or any specific size */
    padding-top: 5%;
  }
}

.loading-text {
  position: relative;
  display: inline-block;
  font-size: 3rem;
}

.loading-text::after {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  animation: dotAnimation 1s infinite;
}

@keyframes dotAnimation {
  0%, 20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  80%, 100% {
    content: '';
  }
}

.rotating-hue {
  animation: hueRotateAnimation 5s infinite linear, doubleBounce 2s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes hueRotateAnimation {
  0% {
    filter: hue-rotate(0deg) drop-shadow(4px 4px 0px black);
  }
  100% {
    filter: hue-rotate(360deg) drop-shadow(4px 4px 0px black);
  }
}

@keyframes doubleBounce {
  0%, 100% {
    transform: translateY(0);
  }
  25%, 75% {
    transform: translateY(4px);
  }
  50% {
    transform: translateY(-2px);
  }
}

#gameover {
  max-width: 1000px;
  width: 100%;
  border: 3px solid black;
  padding: 50px;
  background: rgba(255,255,255, 1);
  filter: drop-shadow(4px 4px 0px black);

  .trainer-image {
    background-color: $pikayellow;
    height: 128px;
    width: 128px;
    background-image: url('../public/images/dots.png');
    background-size: 5px;
    animation: movingDots .5s linear infinite;
    border: 3px solid black;
    overflow: hidden;
    border-radius: 50%;

    img {
      position: absolute;
      max-width: 256px;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      transform: translateX(-25%);
    }
  }

  #score-text {
    font-size: 1.5rem;
  }

  #trainerclass {
    filter: drop-shadow(2px 2px 0px $pikayellow);
    max-height: 100px;
  }

  #slogan {
    font-size: 1.5rem;
  }

}

#report-a-bug {
  h4 {
    font-size: .8rem;
  }
  img {
    height: 18px;
  }
}

// Mobile portrait

@media screen and (max-width: 1325px) {

  .dotted:hover, 
  .dotted.selected {
    filter: drop-shadow(0px 0px 0px black) !important;
    transform: translate(0px, 0px) !important;
  }
}

@media screen and (max-width: 1125px) {

  #game-length-screen .card .image-container {
    height: 200px;
  }
}

@media screen and (max-width: 1050px) {

  #categories-screen {

    #tiles {
      width: 100%;
    }

    #category-images {
      display: none;
    }
  }
}

@media screen and (max-width: 900px) {

  #loading-page .absolute {
    padding-top: 10%;
  }

  #how-to-play-screen,
  #categories-screen,
  #game-length-screen {
    padding-top: 20px;
  }

  .start-screen .press-to-start .banner-button {
    font-size: 1.5rem;

    h4 {
      margin-right: 0;
    }
  }

  .buttons button {
    width: 100px;
  }

  .warning-text-container {
    width: 100% !important;
    position: static;
    margin: 20px 0;
  }

  #how-to-play-screen .instructions-container p {
    font-size: 1rem;
  }

  #tiles .tile h3 {
    font-size: .8rem;
  }

  #game-length-title,
  #categories-title,
  #how-to-play-title {
    height: auto !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  #how-to-play-title {
    max-width: 70% !important;
  }

  .buttons {
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 60px;
  }

  #game-length-screen > .flex {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 285px;

    .card  {
      width: 285px;
      margin-bottom: 20px;

      .image-container {
        height: 250px;
      }
    }
  }

  #how-to-play-screen .instructions-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 378px;

    .card {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  #gameover {
    #score-text, #slogan {
      font-size: 1rem;
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 700px) {
  #loading-page img {
    width: 150px;
    height: 150px;
    padding: 0;
  }
  .start-screen #title {

    #logo-top {
      filter: drop-shadow(2px 2px 0px $pikayellow);
    }
  
    #logo-middle {
      filter: drop-shadow(4px 4px 0px $pikayellow);
    }
  
    #logo-bottom {
      filter: drop-shadow(2px 2px 0px $pikayellow);
    }
  }


  #game-screen {

    .mid-col {
      scale: .8;

      .submit-container {
        width: 639px;
      }
    }

    #navbar .middle h4 {
      padding: 0px 50px;
      font-size: 1rem;
    }

  }

  .loading-text {
    position: relative;
    display: inline-block;
    font-size: 2rem;
  }
}

@media screen and (max-width: 550px) {

  #game-screen .mid-col {
    scale: .7;
  }

  #game-screen #hint-bar {
    display: block;
    height: auto;

    .right {
      margin-top: 10px;

      .show-hint h4 {
        text-align: center;
      }
    }
  }

  .answer-card-img {
    height: auto !important;
  }
}

@media screen and (max-width: 500px) {

  #game-screen .mid-col {
    scale: .65;
  }
}

@media screen and (max-width: 435px) {

  #game-screen .mid-col {
    scale: .6;
  }

  #title .relative {
    max-width: 600px;
  }

}

@media screen and (max-width: 400px) {

  #game-screen {

    #hint-bar h4,
    #navbar h4 {
      font-size: .8rem !important;
    }

    .progress-bar-text {
      padding: 0px 25px !important;
    }

    .mid-col {
      scale: .55;
    }
  }
}

@media screen and (max-width: 360px) {

  #game-screen .mid-col {
    scale: .5;
  }
}

@media screen and (max-width: 325px) {

  #game-screen #navbar h4 {
    font-size: 4vw !important;
  }

  #game-screen #hint-bar .hint {
    font-size: 4vw !important;
    width: 50px;
  }

  #game-screen .mid-col {
    scale: .4;
  }
}


// Short windows 
@media screen and (max-height: 635px) {

  #title .relative {
    max-width: 1000px;
  }
}

@media screen and (max-height: 600px) {

  #how-to-play-screen,
  #categories-screen,
  #game-length-screen {
    padding-top: 20px;
  }

  .buttons {
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 60px;
  }
  #game-screen .mid-col {
    top: -120px;
  }
}

@media screen and (max-height: 585px) {
  #game-screen #content {
    .correct-text-reveal, .incorrect-text-reveal {
      height: 17% !important;
    }
    .correct-img-reveal{
      height: 75% !important;
    }
  }
  #loading-page {
    > .absolute {
      padding-top: 0;
    }
    img {
      max-width: 100px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
    .loading-text {
      font-size: 2rem;
    }
  }
}

@media screen and (max-height: 535px) {

  #game-screen .mid-col {
    top: -150px;
  }

  #title .relative {
    max-width: 800px;
  }

}

@media screen and (max-height: 500px) {
  .start-screen .press-to-start {
    bottom: 0;
  }
}

@media screen and (max-height: 335px) {

  #title .relative {
    max-width: 350px;
  }
}

// Mobile landscape

@media only screen 
  and (min-width: 375px) 
  and (max-width: 667px) {
    #game-screen #content .mid-col {
      top: -13vh;
    }
}

@media screen and (min-width: 701px) and (max-height: 735px) {
  #game-screen .correct-text-reveal, #game-screen .incorrect-text-reveal {
    height: 17%;
  }
  #game-screen .correct-img-reveal{
    height: 75%;
  }
  #game-screen .mid-col {
    scale: .8;
    top: -13vh !important;
  }
}

@media screen and (min-width: 701px) and (max-height: 645px) {

  #game-screen .mid-col {
    scale: .5;
  }
}

@media screen and (min-width: 701px) and (max-height: 445px) {

  #game-screen .mid-col {
    scale: .4;
  }
}

@media screen and (min-width: 500px) and (max-height: 400px) {

  #game-screen .mid-col {
    scale: .5;

    .title {
      display: none;
    }
  }
}